/**
 *
 */

body > .location {
  background: var(--xs-location-background);
  padding: 10px;

  @media (min-width: 768px) {
    padding: 0;
    background: var(--sm-location-background);
  }

  .container {
    background: var(--xs-location-container-background);
    padding: 20px 15px 0;

    @media (min-width: 768px) {
      padding: 0 15px;
      background: var(--sm-location-container-background);
    }

    > .row {
      @media (min-width: 768px) {
        display: flex;
      }

      > .col-sm-6 {
        @media (min-width: 768px) {
          flex: 1;
        }
      }

      > .col-md-5 {
        @media (min-width: 768px) {
          padding: 0;
          margin: 10px;
          background: var(--sm-location-detail-background);
        }

        @media (min-width: 768px) and (max-width: 991px) {
          max-width: 355px;
        }

        @media (min-width: 992px) {
          margin: 0;
          flex-basis: 420px;
          border: var(--border-width) solid var(--md-location-detail-border);
        }
      }
    }
  }

  a {
    color: var(--xs-location-link-color);
  }

  .location-detail {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: var(--xs-location-color);

    @media (min-width: 768px) {
      padding: 20px 10px 0;
    }

    @media (min-width: 992px) {
      padding: 18px 18px 8px;
    }

    .image img {
      max-width: 240px;
      margin-bottom: 20px;

      @media (min-width: 992px) {
        max-width: 300px;
      }
    }

    strong.name {
      margin: 0 0 5px;
      display: block;
      font-size: 18px;
      line-height: 21px;
      font-weight: 600;
      color: var(--xs-location-strong-color);

      @media (min-width: 992px) {
        font-size: 22px;
        line-height: 25px;
      }
    }

    .telephone {
      margin-top: 7px;

      a {
        text-decoration: none;
      }

      span {
        text-decoration: underline;
      }

      a::before {
        content: "\f095";
        font-family: FontAwesome;
        display: inline;
        color: var(--xs-location-link-color);
        padding-right: 1px;
      }
    }

    .btn {
      margin-top: 15px;
      width: 170px;
      padding: 14px 22px 14px 23px;
      line-height: 18px;
      font-size: 16px;
      color: var(--xs-location-button-color) !important;
      background-color: var(--xs-location-button-background);
      border-color: var(--xs-location-button-border);

      &:hover {
        color: var(--xs-location-button-hover-color) !important;
        background-color: var(--xs-location-button-hover-background);
        border-color: var(--xs-location-button-hover-border);
      }
    }

    .aggregate {
      font-size: 13px;
      line-height: 16px;
    }

    .stars {
      margin: 15px 0 0;
      font-size: 24px;
      line-height: 24px;
    }

    .hours,
    .special-hours {
      margin-top: 15px;

      strong {
        font-weight: 600;
      }

      dl {
        margin: 0;
      }

      dt,
      dd {
        display: inline;
        font-weight: normal;
      }

      dt::after {
        content: ": ";
        display: inline;
      }

      dd::after {
        content: "\A";
        white-space: pre;
      }

      dd:last-child::after {
        content: "";
      }

      .special {
        white-space: nowrap;
      }
    }

    .hours .special {
      &::after {
        content: "\f06a\A" !important;
        font-family: FontAwesome;
        color: var(--xs-location-detail-holiday-hours-color);
      }
    }

    .special-hours {
      margin-top: 10px;
      color: var(--xs-location-detail-holiday-hours-color);

      .special {
        &::before {
          content: "\f06a\A" !important;
          font-family: FontAwesome;
          color: var(--xs-location-detail-holiday-hours-color);
        }
      }
    }

    .additional-info {
      padding-top: 15px;

      @media (min-width: 992px) {
        margin: 16px 20px 0;
        padding-top: 5px;
        border-top: 1px solid var(--md-location-detail-additional-info-border);
        text-align: left;
      }

      p {
        font-size: 13px;
      }
    }
  }

  .icons {
    line-height: 12px;
    padding-bottom: 14px;
    margin-top: 21px;

    .fa {
      margin-bottom: 1px;
    }

    a {
      font-size: 12px;
      text-decoration: none;
    }

    i {
      display: block;
      font-size: 25px;
    }

    .col-xs-4 {
      display: inline-block;
      float: none;
      margin: 0 10px;
      padding: 0;
      width: 50px;
    }
  }

  #map-panel-holder {
    @media (min-width: 992px) {
      flex-basis: 550px;
    }

    @media (min-width: 1200px) {
      flex-basis: 750px;
    }
  }

  #map-panel {
    @media (min-width: 768px) {
      height: 100%;
    }
  }

  #location-map {
    @media (min-width: 768px) {
      height: calc(100% - 70px);
    }

    @media (min-width: 992px) {
      height: calc(100% - 90px);
    }
  }

  #location-search {
    @media (min-width: 992px) {
      padding: 18px 48px;
    }

    @media (min-width: 1200px) {
      padding: 18px 148px;
    }
  }

 
}


body > .location {

  .mini-iframe {
    text-align: center;
    background: transparent;
    border: none;
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    padding-top: 15px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .mini-iframe iframe {
    background-color: var(--primary);
  }

  @media (max-width: 767px) {
    .mini-iframe {
      padding-left: 10px;
      padding-right: 10px;
    }
    .mini-iframe iframe{
      min-width: 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 768px) {
    .mini-iframe iframe {
      max-width: 300px;
    }
  }

  @media (min-width: 992px) {
    .mini-iframe iframe {
      max-width: 340px;
    }
  }  

}  

