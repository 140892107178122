/*
 * Custom CSS for client specific overrides
 */

/* COMMON ===================================================================================== */
body .alert strong {
  padding-top: 10px;
}

body .alert p {
  padding-bottom: 10px;
}

body .alert {
  background-color: #8dc63f;
  color: #ffffff;
}


body {
  font-family: "Open Sans", sans-serif;
}



.category-hero img {
  @media (max-width: 767px) {
    min-height: 132px;
  }
}

.category-hero img {
  @media (min-width: 768px) and (max-width: 991px) {
    min-height: 226.2px;
  }
}

.category-hero img {
  @media (min-width: 992px) and (max-width: 1199px) {
    min-height: 283px;
  }
}

.category-hero img {
  @media (min-width: 1200px) {
    min-height: 377px;
  }
}

.locator {
	background-color: #FAF9F7;
}

.locator .container {
	@media (min-width: 992px){
		background-image: url("/assets/dist/images/locator-start-bg-image.jpg");
	}
}

.locator .container {
	@media (max-width: 991px){
		background-image: url("/assets/dist/images/locator-start-bg-image.jpg");
	}
}

.locator-drawer {
	background-color: #8DC63F;
}

.locator-drawer .container h2 > a.dropdown-toggle {
	background-color: #8DC63F;
}

.locator-drawer .container h2 > a.dropdown-toggle:hover {
	background-color: #8DC63F;
}

body .location .location-detail div.secondary {
  padding-top: 10px;
  padding-bottom: 5px;
  color: #4697D1 !important;
}

body .location .mini-iframe {
  margin-top: -10px;
}

body .location a {
  color: #4697D1;
}

body .location .location-detail .additional-info p a {
  color: #0F99D6;
  background-color: transparent;
  border:  2px solid #0F99D6;
  font-size: 16px;
  padding: 10px 47px 10px 47px;
  font-family: "Open Sans";
  font-weight: bold;
  text-decoration: none;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.4);
  display: inline-block;
  min-width: 203px;
}

body .location .location-detail .additional-info p a {
  @media (max-width: 767px) {
    padding: 6px 47px 6px 47px;
  }
}

body .location .location-detail .additional-info p a:hover {
  color: #034694;
  border-color: #034694;
}

body .location .location-detail .additional-info p a:active {
  color: #8DC63F;
  border-color: #8DC63F;
}

.top .navbar-header .navbar-brand,
.navbar-collapse .container .menu-brand a.navbar-brand {
  margin-top: 0px;
  top: 10px;
  background-size: 64px 64px;
}

.top .navbar-header .navbar-brand {
 margin-top: 0px;
 height: 64px;
 background-repeat: no-repeat;
}

body>.location .location-detail .additional-info {
  text-align: center;
  color: #0F99D6;
  text-decoration: underline;
  border-top: none;
}

#menu  { 
  min-height: 100%;
  background: linear-gradient(180deg, #0F99D6 0%, #0F99D6 79.81%, #AAAAAA 80.49%, #FFFFFF 84.74%, #FFFFFF 100%) 0px -10px no-repeat;
}

.top .container {
  background: none;
}

.navbar-collapse .container {
  background: none;
}

.email-signup .panel {
  background: #8dc63f;
}

.email-signup .container {
  padding: 0;
  padding-top: 10px;
  padding-bottom: 0px;
}

.email-signup .panel {
  padding-top: 0;
}

.email-signup .panel .panel-body form input {
  margin-right: 0px;
  color: #505050;
  font-family: Open Sans;
  font-size: 14px;
}

.email-signup .panel .panel-body form .btn {
  position: relative;
  left: -4px;
  height: 50px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
}

.body-cta,
.body-cta .container {
  background: #F2F2F2;
  border: 0px;
  border-color: transparent;
}

.hasPOS .container, .availableFrom .container, .areaServed .container, .makesOffer .container, .containsPlace .container {
  border-bottom: 1px #EEEEEE solid;
  padding-bottom: 0px;
  padding-top: 0px;
}

.hasPOS h2, .availableFrom h2, .areaServed h2, .makesOffer h2, .containsPlace h2 {
  border-top: 0px;
  
}

.hasPOS .location-nearby .btn, .availableFrom .location-nearby .btn {
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.4);
}

.body-cta p {
  font-family: "Open Sans";
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #000000;
  padding-top: 20px;
  padding-bottom: 20px;
}

.body-cta hr {
  display: none;
}


.body-cta .btn-app img {
  padding: 5px;
}

.footer {
  background: linear-gradient(#0f99d6 106px, #fff 107px);

  @media (min-width: 768px) and (max-width: 991px) {
    background: linear-gradient(#0f99d6 120px, #fff 121px);
  }
}

@media (max-width: 767px) {
  .footer ul.disclaimers {
    padding: 20px 0 0;

    & > li a {
      line-height: 33px;
    }
  }  
}

.footer .container {
  padding-top: 0px;
  background: transparent;
}

.footer .container > .row:first-child {
  padding-top: 23px;
  margin-top: 0px;
  height: 100%;
  border: 0px;
  min-height: 106px;

  @media (min-width: 992px) {
    min-height: 106px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-top: 20px;
  }
}

.footer hr {
  display: none;
}

.footer .social {
  padding-top: 10px;
}

.footer .social {
  @media (max-width: 767px) {
    padding: 0;
  }
}

.footer .columns {
  padding-top: 30px;
  border: 0px;
  border-bottom: 1px solid #ccc;
}

.footer .legal {
  margin: 20px 0 10px;

  @media (max-width: 767px) {
    margin-top: 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer ol.breadcrumb {
    margin-bottom: 10px;
  }

  .footer .social {
    padding-bottom: 20px;
  }

  .footer .navbar-nav.social-icons {
    padding-bottom: 0;
  }

  .footer .columns {
    padding-top: 68px;
  }
}

@media (min-width: 992px) {
  .footer.columnar .navbar-text.legal {    
    width: auto;
  }
}

.footer .legal .legal {
  margin-bottom: 20px;
  text-align: left;
}

.footer .legal p {
  margin-bottom: 1em;
}

.app-1, .app-1 .container {
  background: #FFFFFF;
}

.app-1 .app-body h2 {
  font-family: "Open Sans";
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #000;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 440px;
    margin: auto;
}

.app-1 .app-right-holder a.app-right-button:nth-child(1) {
  background: url("/assets/dist/images/google-play-btn.png");
  background-repeat: no-repeat;
  color: transparent !important;
  min-width: 170px;
  margin-top: 10px;
}

.app-1 .app-right-holder a.app-right-button:nth-child(2) {
  background: url("/assets/dist/images/app-store-btn.png");
  background-repeat: no-repeat;
  color: transparent !important;
  min-width: 170px;
  margin-top: -10px;
}

.app-2 {
  background: #F2F2F2;
  border: 0;
  color: #000000;
  text-align: center;
}

.app-2 .container {
  border: 0;
}

.app-2 h2 {
  font-family: "Open Sans";
  font-weight: 600;
}

.app-2 p { 
  font-family: "Open Sans";
  font-weight: bold;
  padding-top: 10px !important;
  padding-left: 0 !important;
}

.app-2 .btn {
  font-family: "Open Sans" !important;
  font-weight: bold !important;
  color: #FFFFFF !important;
  background-color: #8DC63F;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
  border-radius: 0;
}

.app-2 .app-center-holder {
  padding-left: 0px !important;
}

.app-3, .app-3 .container {
  background: #E4E3E1;
}

.app-3, .app-3 .container {
  @media (max-width: 767px) {
    padding: 0px;
  }
}

.app-3 .app-3 .container {
  @media (min-width: 767px) and (max-width: 991px) {
    height:  300px;
    padding: 0px;
  }
}

.app .section.with-center.with-media.no-right .container > .row .media-holder img{
  @media (max-width: 767px) {
    height: 172px;
  }
}

.app .section.with-center.with-media.no-right .container > .row .media-holder img{
  @media (min-width: 767px) and (max-width: 991px) {
    height:  300px;
  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body h2 {
  font-family: Open Sans;
  font-size: 60px;
  line-height: 66px;
  color: #034694;
  padding-top:  40px;
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body h2 {
  @media (max-width: 767px) {
    font-size: 28px;
    font-weight: bold;
    padding-top: 0px;
    margin-bottom: 0px;
  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body h2 {
  @media (min-width: 992px) and (max-width: 1199px) {
    width:  470px;
    text-align: left;
    padding-top:  0px;
    margin-left:  150px;
  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body h2 {
  @media (min-width: 767px) and (max-width: 991px) {
    font-size: 40px;
    line-height: 44px;
    font-weight: bold;
    width: 360px;
    text-align: left;
    margin-left: 140px;
    padding-top: 0px;
  }
}
.app-3 .section.with-center.with-media.no-right .container > .row .app-body p {
  font-family: Open Sans;
  font-size: 21px;
  line-height: 27px;
  color: #034694;
  font-weight: 100;
  padding:  25px 0px 20px 85px;
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body p {
  @media (max-width:  767px) {
    padding:  0px;
    width:  280px;
    font-size: 16px;
    font-weight: 100;
    display: inline-block;
    line-height: 21px !important;

  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body p {
  @media (min-width: 767px) and (max-width: 991px) {
    text-align: left;
    font-size: 18px;
    line-height: 24px !important;
    margin-left:  55px;
    padding-top:  20px;
  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body p {
  @media (min-width: 992px) and (max-width: 1199px) {
    width:  530px;
    text-align: left;
    line-height: 27px !important;
    margin-left:  70px;
    margin-bottom:  0px;

  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body .btn {
  float: left;
  margin-left:  160px;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
  padding: 0px;
  line-height: 50px;
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body .btn {
  @media (max-width: 767px) {
    margin-left:  -20px;
  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body .btn {
  @media (min-width: 767px) and (max-width: 991px) {
  margin-left:  145px;
  }
}

.app-3 .section.with-center.with-media.no-right .container > .row .app-body .btn {
  @media (min-width: 992px) and (max-width: 1199px) {
    margin-left:  150px;
  }
}

.x6-1, .x6-2 {
  background: #FFFFFF;
}

.x6-1 .container, .x6-2 .container{
  background: #FFFFFF;
}


.x6-1 h2, .x6-2 h2 {
  color: #034694 !important;
  font-family: "Open Sans" !important;
}

.x6-1 .category-item a > span  {
  background: #0F99D6 !important;
  color: #FFFFFF  !important;
}

.x6-2 .category-item a > span  {
  display: none !important;
}

.x6-1 .category-item a:hover {
  opacity: 0.7;/* MISSING HOVER IMAGES*/
}


.x6-2 .category-item a:hover {
 opacity: 0.5;
}

.x6-2 li, .x6-1 li {
  align-items: center;
  justify-content: center;
}

#list-panel .place a {
  text-decoration: none;
}
.footer ol.breadcrumb > li a:hover,
.footer .navbar-nav.social-icons > li a:hover {
  color: #000000 !important;
}

.footer ol.breadcrumb > li a:focus:active,
.footer .navbar-nav.social-icons > li a:focus:active {
  color: #CCCCCC !important;
}

.banner {
  padding-top: 30px;
}
.btn-danger:active:focus {
  background: #034694;
}

#location-list.error,
#location-list.error, #location-list p.no-locations {
  background: #FFFFFF;
  color: #000000;
}

.app-3 .section.with-center.with-media.no-right .container > .row {
  padding: 0px;
  padding-top:  30px;
}


.app .section.with-center.with-media.no-right .container > .row .app-body .btn {
  text-transform: uppercase;
}


.app .section.with-center.with-media.no-right .container > .row .app-body .btn:hover {
  background: #034694 !important;
}


.app .section.with-center.with-media.no-right .container > .row .app-body .btn:active:focus {
  background: #0F99D6 !important;
}


#list-panel .pager a.right:active,
#list-panel .pager a.left:active {
  background: #8dc63f;
}

#start-geocoder input {
  color: #000000;
}

#list-panel .place .btn {
  text-transform: none;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.4);
}

#map-panel-holder .mapboxgl-popup-content a {
  text-decoration: none;
}

body > .location .location-detail .btn {
  text-transform: none;
}

.categories .container .categories-grid.grid-6 .category-item a > span {
  font-size: 13px;
}

.app .section.with-center.with-media.no-right .container > .row .app-body p {
  line-height: 22px;
}

.category-hero .container ul .btn {
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.4);
}

body > .location .location-detail .telephone span {
  text-decoration: none;
}

#list-panel .place .telephone a::before {
    font-family: FontAwesome;
    content: "\f095";
    display: inline-block;
    text-decoration: none;
    padding-right: 3px;
    vertical-align: bottom;
}

#list-panel .place .address .hidden-xs{
  display: block !important;
}

#list-panel .icons .fa::after {
  padding-top: 4px;
  display: block;
  color: #0F99D6;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 12px;
}

#list-panel .icons .fa-map-marker::after {
  content: "Map";
}


#list-panel .icons .fa-newspaper-o::after {
  content: "Details";
  
}

#list-panel .icons .fa-phone::after {
  content: "Call";
}

#list-panel .icons .fa-search::after {
  content: "Search";
}

body > .location .mini-iframe iframe {
	background: transparent;
}


/* XS ===================================================================================== */
@media (min-width: 300px){
 
  .body-cta .container {
    padding-top: 20px;
    padding-bottom: 0px
  }

  .body-cta .row {
    display: flex;
    flex-direction: column-reverse;
  }

  .form-inline .form-group {
    display: inline-block;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
  }


  .top {
    height: 70px;
    background: linear-gradient(180deg, #0F99D6 0%, #0F99D6 79.81%, #AAAAAA 80.49%, #FFFFFF 84.74%, #FFFFFF 100%) 0px 0px no-repeat;
  }  

  .top .navbar-right .navbar-nav.utility {
    position: relative;
    left: -20px;
  }
  .top .navbar-right .navbar-nav > li:last-child > a {
    color: #FFFFFF;
    top: 10px;
    left: -10px;
    text-decoration: none;
    font-family: "Open Sans"; 
    font-size: 13px;  
    font-weight: 600; 
    line-height: 14px;
  }
  .top .navbar-right .navbar-nav > li:last-child > a:hover {
    background: #FFFFFF;
    color: #0F99D6;
  }

  .top .navbar-right .navbar-nav > li:last-child > a::after {
    content: "\f0da";
    font-family: FontAwesome;
    padding: 5px;
  }

  .app .section.with-center.with-media.no-right .container > .row .app-body .btn{
    height: 44px !important;
    min-width: 196px !important;
  }

  .app-2 h2 {
    font-size: 20px;
  }

  .app-2 p {
    font-size: 13px;
  }

  .app-2 .media-holder iframe {
    padding: 0px !important;
    height: 181px;
    width: 320px;
  }
}

.app .media-holder {
	@media (max-width: 767px) {
		display: contents;
	}
}

.categories .container .categories-grid.grid-6 .category-item {
  	padding: 0px;
 }

 .categories .container .categories-grid.grid-6 .category-item {
  @media (max-width: 767px) {
    padding: 2.5px;
    display:  inline-block;
  }
}

.categories.x6 .container h2 {
	margin-bottom: 20px;
}

.categories.x6 .container {
	padding-bottom: 20px;
}

@media (max-width: 420px) { 
  .email-signup .panel .panel-body form input {
    width: 190px;
    height: 50px;
  }

  .email-signup .panel .panel-body form .btn {
    width: 90px;
    height: 50px;
    padding: 0px;
  }
  .footer {
    max-width: 100vw !important;
    width: 100vw !important;
    text-align: center;
  }
}

/* SM ===================================================================================== */

@media (min-width: 768px) {
  .categories .container .categories-grid.grid-6 .category-item a > span {
    font-size: 16px;
  }

  .categories .container .categories-grid.grid-6 .category-item {
  	padding: 5px;
  }


  .categories .container h2 {
    font-size: 24px;
  }

  .top .navbar-right .navbar-nav > li:last-child > a {
    left: 0px;
    top: 20px;
  }

  .email-signup .panel .panel-heading {
    display: inline-block;
    padding: 10px;
  }

  .email-signup .panel .panel-body {
    display: inline-block;
  }

  .body-cta .container {
    background: #F2F2F2;
    max-height: 180px;
    padding-top: 22px;
  }

  .body-cta .img-block {
    position: relative;
    margin-top: -15px;
  }

  .body-cta .row {
    display: flex;
    flex-direction: row;
  }

  .body-cta .btn-holder {
    text-align: right;
  }

  .app-2 .media-holder iframe {
    padding: 0px !important;
    height: 181px;
    width: 320px;
  }
  
  .app-2 .container {
    border: 0;
  }


  .app-2 .col-sm-3 {
    width: 35%;
    padding-top: 25px !important;
  }

  .app-2 .col-sm-9{
  	float: right;
    width: 65%;
    padding-left: 95px !important;
  }

  .app-2 {
    text-align: left;
  }

 
} 

@media (max-width: 991px) {

  .header-sm {
    padding-top: 20px;
    background: #FFFFFF;
  }

  #menu {
    max-width: 320px;
    max-height: 30px;
    min-height: 340px;
    background: #0F99D6;
    top: 8px;
  }

  .navbar .navbar-toggle {
    background: transparent;
    left: 137px;
    top: 8px;
  }
  .top .logos {
    position: relative;
    left: -270px;
    top: -5px;
  }

  #top-utility-links {
    display: none;
  }


  .navbar-default .navbar-toggle .icon-bar:last-child::after {
    content: "Menu";
    display: block;
    font-size: 9px;
    position: relative;
    top: 12px;
    color: #FFFFFF;
  }
  
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a{
    color: #FFFFFF !important;
    background: #0f99d6 !important;
  }

  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:hover {
    color: #0f99d6 !important;
    background: #FFFFFF !important;
  }

  .navbar-collapse ul.navbar-nav {
   background: #FFFFFF;
   padding: 5px;
  }

  .navbar-collapse ul.navbar-nav .open > a, .navbar-collapse ul.navbar-nav > li > a {
    border-width: 1px;
  }

  
}

@media (max-width: 767px) {
  
  .navbar .navbar-toggle {
    left: 92px;
    top: 8px;
  }

  .top .logos {
    padding-left: 20px;
    left: 0px;
    top: 5px;
  }

  .footer .columns .navbar-nav > li {
    display: block;
  }

  .body-cta .row div:nth-child(2) { 
    order: 1;
  }

  .body-cta .row div:nth-child(3) { 
    margin-bottom: 30px;
  }


}


/* MD and LG ===================================================================================== */


@media (min-width: 992px) {

  .categories .container .categories-grid.grid-6 .category-item a > span {
    font-size: 18px;
  }

  .respond-sm, .respond-xs {
    display: block !important;
  }  

  .navbar-right {
    display: none !important;
  }  

  .top {
    display: none;
  }  

  .navbar-collapse ul.navbar-nav .open > a, .navbar-collapse ul.navbar-nav > li:not(.menu-brand)  > a {
    height: 55px;
    background: transparent;
    top: -5px;
   }

  .navbar-collapse ul.navbar-nav .open > a:focus, 
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:focus {
    background: #0f99d6  !important;
    color: #FFFFFF !important;
  }

  .navbar-collapse ul.navbar-nav .open > a:hover, 
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:hover {
    height: 55px;
    color: #FFFFFF !important;
    background: transparent;
  }
  
  .navbar-collapse ul.navbar-nav .open > a:active, 
  .navbar-collapse ul.navbar-nav > li:not(.menu-brand) > a:active {
    height: 55px;
    color: #ffffff !important;
    background-color: transparent;
  }  

body > .location .location-detail .btn:hover {
	background-color: #8dc63f;
	color: #ffffff;
}

body > .location .location-detail .btn {
    width: fit-content;
}


  .header .container {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }

  .app-2 h2 {
    font-size: 24px;
  }

  .app-2 p {
    font-size: 15px;
  }
  
  .app-2 .media-holder iframe {
    height: 181px;
    width: 320px;
  } 
  .app-2 .col-sm-3 {
  	padding-top: 20px !important;
  }
  
}

/* LG ===================================================================================== */

@media (min-width: 1200px) {

  .app-2 .media-holder iframe {
    height: 200px;
    width: 360px;
  }

  .app-2 .col-sm-3 {
  padding-top: 10px !important;
  } 

}




/*MOBILE DEVICES*/

@media (hover: none) { 
  .header .cta .btn:hover,
  #start-geocoder .geocoder-pin-far-right .btn-search:hover,
  #start-geocoder .geocoder-pin-far-right .btn-search::before,
  #start-geolocate .location-holder .btn:hover,
  #location-search .btn-search:hover,
  #list-panel .place .btn:hover,
  .body-cta .btn-danger:hover,
  #list-panel .pager a.right:hover,
  #list-panel .pager a.left:hover,
  body > .location .location-detail .btn:hover,
  .email-signup .panel .panel-body form .btn:hover
  {
    color: #FFFFFF  !important;
    background: #0f99d6 !important;
  }

  .header .cta .btn:active, 
  #start-geocoder .geocoder-pin-far-right .btn-search:active,
  #start-geolocate .location-holder .btn:active,
  #location-search .btn-search:active,
  #list-panel .place .btn:active,
  .body-cta .btn-danger:active,
  #list-panel .pager a.right:active,
  #list-panel .pager a.left:active,
  body > .location .location-detail .btn:active,
  .email-signup .panel .panel-body form .btn:active
  {
     color: #FFFFFF  !important;
     background: #034694 !important;
  } 

}

.navbar-collapse ul.navbar-nav li.close > a:hover,
.navbar-collapse ul.navbar-nav li.close > a:focus {
  color: #0f99d6 !important;
  background: #FFFFFF !important;
  .fa {
    color: #0f99d6 !important;
  }
}

.navbar-collapse ul.navbar-nav li.close > a:active {
  color: #FFFFFF !important;
  background: #0f99d6 !important;
  .fa {
    color: #FFFFFF !important;
  }
}


#map > .row {
  &::before {
    display: block;
    content: "";
  }
}

#start-geolocate {
  position: relative;
  margin: 0 -15px;

  &::after {
    display: block;
    content: "";
    background: var(--sm-locator-start-background) center center;
    right: 0;
    position: absolute;
    @media (min-width: 768px) {
      top: -65px;
      bottom: -65px;
    }
  }
}

.geolocate-input {
  padding: 20px;
  min-height: 100px;
  position: relative;
  z-index: 1;

  @media (min-width: 768px) {
    margin: 70px 55px;
    min-height: 0;
    padding: 0;
  }

}

#location-search .mapboxgl-ctrl-geocoder input {
	@media (max-width: 767px){
		font-size: 16px
	}
}

hasPOS h2, .availableFrom h2, .areaServed h2, .makesOffer h2, .containsPlace h2 {
	@media (min-width: 1200px) {
		padding-bottom: 36px;
	}
}

.hasPOS .list-group-item a, .availableFrom .list-group-item a, .areaServed .list-group-item a, .makesOffer .list-group-item a, .containsPlace .list-group-item a {
	text-decoration: none;
}

#start-geolocate .location-holder .btn {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
}

#start-geocoder input {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
	font-family: Open Sans;
	font-size: 16px;
	color: #505050;
}

#start-geocoder .geocoder-pin-far-right .btn-search {
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.5);
}

body>.location a {
	@media (max-width: 767px) {
		text-decoration: none;
		line-height: 30px;
	}
}

#list-panel .icons .col-xs-3:last-child {
	display: none;
}

body>.location .location-detail .btn {
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.4);
	width: fit-content;
}

.availableFrom .location-nearby .location-nearby-directions, .hasPOS .location-nearby .location-nearby-directions {
	text-decoration: none;
}

.availableFrom .location-nearby .location-nearby-name, .hasPOS .location-nearby .location-nearby-name {
	text-decoration: none;
}

#location-search .btn-search {
	box-shadow: 1px 1px 2px 0 rgba(0,0,0,0.35);
}

#location-search .mapboxgl-ctrl-geocoder input {
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.35);
}

#list-panel .pager a.right {
	border-radius: 0;
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.35);
}

#list-panel .pager a.left {
	box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.35);
	border-radius: 0;
}

.x6-1 .container .categories-grid.grid-6 .category-item {
	@media (max-width: 767px){
	background-color: #0F99D6;
	margin: 2px;
	width: auto;
	border-radius: 0;
	}
}

.x6-1 .container .categories-grid.grid-6 .category-item {
	@media (min-width: 768px){
	background-color: #0F99D6;
	margin: 2px;
	}
}

.x6-1 .container .categories-grid.grid-6 .category-item {
	@media (min-width: 992px){
	background-color: transparent;
	margin: -3px;
	}
}

.categories-2 .container .categories-grid.grid-6 .category-item a > span {
	display: none;
}

.hasPOS .location-nearby .location-nearby-phone-number span, .availableFrom .location-nearby .location-nearby-phone-number span{
	text-decoration: none;
}

body > .location a {
	text-decoration: none;
}

.categories .container .categories-grid {
	@media (max-width: 767px){
		justify-content: center;
	}
}



@media (min-width: 1200px){
  .header h1 {
      margin-top: 0px;
      padding-top: 21px;
  }

}
